import { AriaModalOverlayProps } from "@react-aria/overlays";
import { OverlayTriggerState } from "@react-stately/overlays";
import { OverlayTriggerProps } from "@react-types/overlays";
import clsx from "clsx";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { mergeProps, Overlay, useModalOverlay, useOverlayTrigger } from "react-aria";
import { useOverlayTriggerState } from "react-stately";
import IconCloseThin from "svg/icon-close-thin.svg";

import { Portal } from "../../components/Portal";
import { usePreventScroll } from "../../hooks/usePreventScroll";
import { _DrawerContainer } from "./components/DrawerContainer";
import { _DrawerTitle } from "./components/DrawerTitle";
import styles from "./Drawer.style.scss";

interface IDrawerProps extends OverlayTriggerProps {
  right?: boolean;
  className?: string;
  classNames?: {
    wrapper?: string;
  };
  children: React.ReactNode | ((state: OverlayTriggerState) => React.ReactNode);
}

type DrawerSubComponents = {
  Container: typeof _DrawerContainer;
  Title: typeof _DrawerTitle;
};

export const Drawer: FunctionComponent<IDrawerProps> & DrawerSubComponents = ({
  children,
  right,
  className,
  classNames,
  ...props
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const state = useOverlayTriggerState(props);

  const { modalProps, underlayProps } = useModalOverlay(
    { isDismissable: true, isKeyboardDismissDisabled: false },
    state,
    ref,
  );

  return (
    <Overlay>
      <div
        className={clsx(styles.background, state.isOpen && styles.open, right && styles.right, classNames?.wrapper)}
        onTouchEnd={(e) => {
          // Avoid elements behind the dropdown to be clicked on touch devices
          if (e.target === e.currentTarget) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
        {...underlayProps}
      >
        <div ref={ref} className={styles.drawer} {...modalProps}>
          <div className={styles.closeButton} onClick={state.close}>
            <IconCloseThin />
          </div>
          <div className={clsx(styles.container, className)}>
            {children instanceof Function ? children(state) : children}
          </div>
        </div>
      </div>
    </Overlay>
  );
};

Drawer.Container = _DrawerContainer;
Drawer.Title = _DrawerTitle;
